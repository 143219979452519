@import '~react-image-gallery/styles/css/image-gallery.css';
@import '~react-image-crop/dist/ReactCrop.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --toastify-font-family: 'Montserrat', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  --app-font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --toastify-toast-width: 450px;

  --msg-input__padding: 0px 12px !important;
  --msg-input__background: #ffffff !important;
  --msg-input__send__fontFamily: inherit !important;
  --msg-input__send__color: #729879 !important;
  --msg-input__textarea__fontFamily: inherit !important;
  --msg-input__textarea__fontSize: 14px !important;
  --msg-input__textarea__lineHeight: 1.5 !important;
  --msg-input__textarea__borderRadius: 15px !important;
  --msg-input__textarea__height: 48px !important;
  --msg-input__textarea__padding: 11px 14px 11px !important;
  --msg-input__textarea__background: #f7f9fa !important;

  --msg-list__background: #ffffff !important;
  --msg-list__padding: 0 !important;
  --msg-list__unread__background: var(--chat--light__color--2) !important;
  --msg-list__unread__borderRadius: 20px !important;
  --msg-list__unread__color: var(--chat--light__background--2) !important;
  --msg-list__unread__fontSize: 13px !important;
  --msg-list__unread__fontWeight: 400 !important;
  --msg-list__unread__padding: 8px 16px !important;
  --msg-list__unread__offset: 50px !important;
  --msg-list__spinner__color: var(--chat--light__color--2) !important;
  --msg--hover__background: #f7f9fa !important;
  --msg__alignItems: flex-start !important;
  --msg__flexDirection: row !important;
  --msg__height: auto !important;
  --msg__padding: 10px 16px !important;
  --msg__actions--hover__background: transparent !important;
  --msg__actions--hover__color: var(--chat--light__hover--1) !important;
  --msg__actions--hover__border: 1px solid var(--chat--light__border--1) !important;
  --msg__actions__background: transparent !important;
  --msg__actions__border: 1px solid var(--chat--light__border--1) !important;
  --msg__actions__borderRadius: 15px !important;
  --msg__actions__color: var(--chat--light__color--2) !important;
  --msg__actions__fontSize: 22px !important;
  --msg__actions__margin: 0 0 0 4px !important;
  --msg__actions__padding: 0 7px !important;
  --msg__actions__right: 10px !important;
  --msg__actions__top: 10px !important;
  --msg__author__color: var(--chat--light__color--1) !important;
  --msg__author__fontSize: 14px !important;
  --msg__author__fontWeight: 500 !important;
  --msg__author__padding: 0 !important;
  --msg__avatar__display: flex !important;
  --msg__avatar__fontSize: 10px !important;
  --msg__avatar__margin: 2px 12px 0 0 !important;
  --msg__avatar__size: 36px !important;
  --msg__avatar__borderRadius: 36px !important;
  --msg__bubble__background: #f3f3f3 !important;
  --msg__bubble__boxShadow: none !important;
  --msg__bubble__borderRadius: 16px !important;
  --msg__bubble__color: var(--chat--light__color--1) !important;
  --msg__bubble__fontSize: 14px !important;
  --msg__bubble__fontWeight: 400 !important;
  --msg__bubble__margin: 0 !important;
  --msg__bubble__padding: 10px !important;
  --msg__content__alignItems: flex-start !important;
  --msg__content__flexDirection: column !important;
  --msg__content__margin: 0 !important;
  --msg__image__borderRadius: 10px !important;
  --msg__image__margin: 15px 0 0 !important;
  --msg__link__margin: 10px 0 0 !important;
  --msg__link__padding: 20px !important;
  --msg__link-description__color: var(--chat--light__color--2) !important;
  --msg__link-thumb__maxWidth: 210px !important;
  --msg__link-title__color: var(--chat--light__color--1) !important;
  --msg__main__alignItems: flex-start !important;
  --msg__main__flexDirection: column !important;
  --msg__main__margin: 0 !important;
  --msg__main__maxWidth: 60% !important;
  --msg__time__display: block !important;
  --msg__time__color: var(--chat--light__color--1) !important;
  --msg__time__fontSize: 11px !important;
  --msg__time__fontWeight: 300 !important;
  --msg__time__padding: 0 10px !important;
  --msg__title__alignItems: baseline !important;
  --msg__title__display: flex !important;
  --msg__title__flexDirection: row !important;
  --msg__title__margin: 0 0 4px !important;
  --msg__reaction--hover__background: var(--chat--light__hover--4) !important;
  --msg__reaction--hover__border: 1px solid var(--chat--light__border--2) !important;
  --msg__reaction--active__background: rgba(
    var(--chat--light__background--5-rgb),
    0.2
  ) !important;
  --msg__reaction--active__border: 1px solid transparent !important;
  --msg__reaction--active--hover__background: rgba(
    var(--chat--light__background--5-rgb),
    0.24
  ) !important;
  --msg__reaction--active--hover__border: 1px solid
    rgba(var(--chat--light__background--5-rgb), 0.4);
  --msg__reaction__background: transparent !important;
  --msg__reaction__border: 1px solid var(--chat--light__border--1) !important;
  --msg__reaction__borderRadius: 15px !important;
  --msg__reaction__margin: 10px 5px 0 0 !important;
  --msg__reaction__padding: 0 7px 0 3px !important;
  --msg__reaction__wordSpacing: 2px !important;
}

.swal2-popup {
  border-radius: 20px;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

.swal2-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  color: #2e2c2f;
}

.swal2-html-container {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #475b63;
  padding-left: 48px;
  padding-right: 48px;
}

div:where(.swal2-container) .swal2-html-container {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #475b63;
  padding-left: 48px;
  padding-right: 48px;
}

.swal2-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 48px;
  padding-right: 48px;
  gap: 20px;
}

.swal2-actions button {
  flex: 1;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #8c794e;
}

.swal2-confirm {
  border-radius: 6px;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

.swal2-cancel {
  padding: 13px 20px;
  border-radius: 6px;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

.app-header-link {
  text-decoration: none;
  color: #2e2c2f;
}

.app-header-link.active {
  text-decoration: underline;
}

.image-gallery-thumbnail {
  width: auto;
  border: none;
  opacity: 60%;
  cursor: pointer;
  transition: all 0.2s;
}

.image-gallery-thumbnail:not(:last-child) {
  margin-right: 20px;
}

.image-gallery-thumbnail.active {
  border: none;
  opacity: 100%;
}

.image-gallery-thumbnail:hover {
  border: none;
  opacity: 100%;
}

.image-gallery-thumbnail:focus {
  border: none;
  opacity: 100%;
}

.image-gallery-thumbnails {
  padding-top: 16px;
  padding-bottom: 16px;
}

.image-gallery-slide {
  box-sizing: border-box;
  padding: 4px;
  border-radius: 16px;
  background-color: #fafafa;
}

.image-gallery-image {
  border-radius: 16px;
  aspect-ratio: 3 / 2;
  width: 100%;
  height: auto;
  object-fit: cover !important;
}

.image-gallery.fullscreen-modal {
  background: transparent;
  z-index: 9999;
}

.image-gallery-content.fullscreen {
  background: #00000033;
}

.image-gallery.fullscreen-modal .image-gallery-content {
  top: 0;
  transform: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-gallery.fullscreen-modal .image-gallery-slide-wrapper {
  width: 100%;
}

.image-gallery.fullscreen-modal
  .image-gallery-slide-wrapper
  .image-gallery-slide {
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  background-color: #000000aa;
  padding: 16px;
}

.image-gallery.fullscreen-modal
  .image-gallery-slide-wrapper
  .image-gallery-slide
  img {
  width: 100%;
  height: 100%;
  max-height: none;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 200px;
}

.error .ql-toolbar.ql-snow {
  border-color: #dc1414;
}

.error .ql-container.ql-snow {
  border-color: #dc1414;
}

.ql-container {
  font-family: 'Montserrat', serif;
  font-size: 16px;
}

:root {
  --wy-font-family: 'Montserrat', sans-serif;

  --wy-primary-0: #000000;
  --wy-primary-10: #251a00;
  --wy-primary-20: #3e2e00;
  --wy-primary-25: #4c3900;
  --wy-primary-30: #5a4300;
  --wy-primary-35: #684f00;
  --wy-primary-40: #775a00;
  --wy-primary-50: #957200;
  --wy-primary-60: #b48b0e;
  --wy-primary-70: #d1a52e;
  --wy-primary-80: #efc048;
  --wy-primary-90: #ffdf98;
  --wy-primary-95: #ffefd2;
  --wy-primary-98: #fff8f2;
  --wy-primary-99: #fffbff;
  --wy-primary-100: #ffffff;

  --wy-neutral-0: #000000;
  --wy-neutral-10: #1e1b16;
  --wy-neutral-20: #33302a;
  --wy-neutral-25: #3f3b35;
  --wy-neutral-30: #4a4640;
  --wy-neutral-35: #56524b;
  --wy-neutral-40: #625e57;
  --wy-neutral-50: #7b766f;
  --wy-neutral-60: #959088;
  --wy-neutral-70: #b0aaa2;
  --wy-neutral-80: #ccc5bd;
  --wy-neutral-90: #e9e1d9;
  --wy-neutral-95: #f7f0e7;
  --wy-neutral-98: #fff8f2;
  --wy-neutral-99: #fffbff;
  --wy-neutral-100: #ffffff;

  --wy-error-0: #000000;
  --wy-error-10: #410002;
  --wy-error-20: #690005;
  --wy-error-25: #7e0007;
  --wy-error-30: #93000a;
  --wy-error-35: #a80710;
  --wy-error-40: #ba1a1a;
  --wy-error-50: #de3730;
  --wy-error-60: #ff5449;
  --wy-error-70: #ff897d;
  --wy-error-80: #ffb4ab;
  --wy-error-90: #ffdad6;
  --wy-error-95: #ffedea;
  --wy-error-98: #fff8f7;
  --wy-error-99: #fffbff;
  --wy-error-100: #ffffff;

  --wy-theme: #8c794e;
}

.wy-messenger-provider {
  background-color: transparent;
  overflow: hidden;
  height: calc(100vh - 240px);
}

.wy-messenger-sidebar {
  background-color: #ffffff;
}

.wy-messenger-conversation {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: calc(100vh - 240px);
}

.wy-pane-body {
  flex: 1;
  overflow: hidden;
}

.wy-messages {
  overflow: auto;
}

.wy-appbars {
  background-color: #ffffff;
}

.wy-button {
  background-color: #eeeeee56;
  color: #333333;
}

.wy-item-hover {
  background-color: #ffffff;
}

.wy-item-hover:hover {
  background-color: #f3f3f3;
}

.wy-item-hover.wy-active {
  background-color: rgba(114, 152, 121, 0.15);
}

.cm-editor {
  background-color: #ffffff !important;
}

.wy-date-separator time {
  background-color: #eeeeee;
}

.wy-message-bubble {
  border-radius: 8px;
  color: #333333;
  font-size: 14px;
}

.wy-message-me .wy-message-bubble {
  border-radius: 8px;
  background-color: #8c794e33;
  color: #333333;
  font-size: 14px;
}

.wy-dropdown-menu {
  background-color: #ffffff;
}
